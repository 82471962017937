import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store/index.js'
import { toastMixin } from '@/mixins/toastMixin.js'

import loginRoutes from './login.js'
import adminRoutes from './admin.js'
import patronRoutes from './patron.js'
import judgeRoutes from './judge.js'
import commentatorRoutes from './commentator.js'
import secretaryRoutes from './secretary.js'

const routes = [
    {
        path: '/:pathMatch(.*)',
        name: 'not-found',
        component: () => import('../views/NotFoundView.vue'),
        meta: { title: 'Erro 404 - não encontrado' }
    },
    {
        path: '/unauthorized',
        name: 'unauthorized',
        component: () => import('../views/UnauthorizedView.vue'),
        meta: { title: 'Erro 401 - não autorizado' }
    },
    {
        path: '/:ctg/commentator',
        name: 'commentator.events.list.view',
        component: () => import('../views/commentator/main/IndexView.vue'),
        meta: { title: 'Erro 401 - não autorizado' }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    linkActiveClass: 'active'
})

;[
    loginRoutes,
    adminRoutes,
    patronRoutes,
    secretaryRoutes,
    judgeRoutes,
    commentatorRoutes
].forEach((group) => {
    group.forEach((route) => {
        router.addRoute(route)
    })
})

router.beforeEach(async (to, from, next) => {
    const loggedUser = store.getters['auth/getLoggedUser']
    const jwtExpiration = store.getters['auth/getExpiration']
    const isSessionsExpired =
        jwtExpiration || jwtExpiration === 0
            ? jwtExpiration < Date.now()
            : false

    if (
        loggedUser &&
        !isSessionsExpired &&
        (to.name === 'login.view' || to.path === '/')
    ) {
        if (loggedUser.role === 'Patrão') {
            next({
                name: 'patron.events.list.view',
                params: { ctg: loggedUser.ctg._id }
            })
            return
        }

        if (loggedUser.role === 'Secretária') {
            next({
                name: 'secretary.events.list.view',
                params: { ctg: loggedUser.ctg._id }
            })
            return
        }

        if (loggedUser.role === 'Juíz') {
            next({
                name: 'judge.events.list.view',
                params: { ctg: loggedUser.ctg._id }
            })
            return
        }

        if (loggedUser.role === 'Narrador') {
            next({
                name: 'commentator.events.list.view',
                params: { ctg: loggedUser.ctg._id }
            })
            return
        }

        if (loggedUser.role === 'Administrador') {
            next({ name: 'users.list.view' })
            return
        }
    }

    const needAuth = to.meta?.needAuth || false

    if (!loggedUser && needAuth) {
        toastMixin.methods.toast(
            'warning',
            'Realize o login novamente para acessar o sistema.',
            false,
            5000
        )
        next({ name: 'login.view' })
        return
    }

    if (!loggedUser && to.path === '/') {
        next({ name: 'login.view' })
        return
    }

    if (isSessionsExpired && loggedUser) {
        toastMixin.methods.toast(
            'warning',
            'Realize o login novamente para acessar o sistema.',
            false,
            5000
        )
        store.dispatch('auth/logout')
        next({ name: 'login.view' })
        return
    }

    next()
})

export default router
