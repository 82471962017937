<template>
    <div class="h-100">
        <router-view />
    </div>
</template>

<script>
import { toast } from 'vue3-toastify'

export default {
    name: 'AppView',
    data() {
        return {}
    },
    methods: {
        toast(type, message, clearOnUrlChange = false) {
            if (type == 'error') {
                toast.error(message, {
                    theme: 'dark',
                    clearOnUrlChange: clearOnUrlChange,
                    dangerouslyHTMLString: true,
                    autoClose: 2000
                })

                return
            }

            toast.success(message, {
                theme: 'dark',
                clearOnUrlChange: clearOnUrlChange,
                dangerouslyHTMLString: true,
                autoClose: 2000
            })
        }
    }
}
</script>

<style>
#app {
    font-family: 'Roboto', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin: 0;
    height: 100%;
    width: 100%;
}
</style>
